module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-117865341-1","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"8853288","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3PTXMQ","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Finnoconsult website","short_name":"Finno","start_url":"/","description":"FinTech Innovationsberatung von Finnoconsult","lang":"de","background_color":"#fff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icons/logo/favicon_256x256.png","icons":[{"src":"favicons/icon-32x32.png","sizes":"32x32","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable any"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable any"}],"localize":[{"start_url":"/en/","lang":"en","description":"FinTech Innovation Consulting from Finnoconsult"},{"start_url":"/fr/","lang":"fr","description":"Nous sommes un cabinet de conseil en innovation pour les entreprises du secteur de la finance et de l'assurance."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7752e6ff14b086d805385ae01f172dd4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/puteani-hollakos/Documents/Development/Work/finnoconsult-website/finnoconsult.at/src/config/intl","languages":["de","en","fr"],"defaultLanguage":"de","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
